@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;1,400&display=swap');

.veiled-welcome {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding-top: 80px;
  background: transparent;
}

.veiled-content {
  text-align: center;
  padding: 2rem;
  background: transparent;
  position: relative;
  z-index: 1000;
  max-width: 800px;
}

.veiled-content h1 {
  font-size: 4.5rem;
  color: white;
  font-weight: 600;
  text-shadow: 0 0 20px rgba(74, 158, 255, 0.5);
  letter-spacing: 2px;
  margin: 0;
  line-height: 1.2;
  background: linear-gradient(45deg, #fff, #4a9eff);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 8s ease infinite;
}

.veiled-paths {
  margin: 2rem 0;
  position: relative;
}

.veiled-paths h2 {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 0;
  padding: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.veiled-paths h2:hover {
  color: #4a9eff;
}

.veiled-links {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(74, 158, 255, 0.3);
  border-radius: 8px;
  padding: 1rem;
  display: none;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 250px;
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.veiled-links.active {
  display: flex;
}

.veiled-link {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.veiled-link:hover {
  background: rgba(74, 158, 255, 0.2);
  color: #4a9eff;
}

.content-section {
  margin-top: 2rem;
  color: #fff;
  max-width: 800px;
  text-align: left;
}

.content-section p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
  .veiled-links {
    grid-template-columns: 1fr;
  }
} 